import React from "react";
import logoNear from "../assets/icons/logo_near.svg";
import elf_example_1 from "../assets/icons/elf-example-1.png";
import elf_example_2 from "../assets/icons/elf-example-2.png";
import elf_example_3 from "../assets/icons/elf-example-3.png";
import elf_example_4 from "../assets/icons/elf-example-4.png";
import Header from "./Header";
import Wallet from "./Wallet";

export default class Feature extends React.Component {
  constructor(params) {
    super(params);

    this.state = {
      isMenuOpened: false,
      menuFlippedHandler: params.menuFlippedHandler,
    };
  }

  menuFlippedHandler(isOpened) {
    this.setState({
      isMenuOpened: isOpened,
      menuFlippedHandler: this.state.menuFlippedHandler,
    });

    this.state.menuFlippedHandler(isOpened);
  }

  render() {
    return (
      <div className="gradient-main">
        <Header
          menuFlippedHandler={(isOpened) => {
            this.menuFlippedHandler(isOpened);
          }}
        />
        {!this.state.isMenuOpened ? (
          <div className="bg-bottom padding-base main relative">
            <div className="pics no-desktop relative">
              <div>
                <img
                  width="40%"
                  className="absolute pic pic-1"
                  src={elf_example_1}
                  alt=""
                />
                <img
                  width="40%"
                  className="absolute pic pic-2"
                  src={elf_example_2}
                  alt=""
                />
                <img
                  width="40%"
                  className="absolute pic pic-3"
                  src={elf_example_3}
                  alt=""
                />
                <img
                  width="40%"
                  className="absolute pic pic-4"
                  src={elf_example_4}
                  alt=""
                />
              </div>
              <div className="bottom-0 label">
                <div className="">
                  <p className="pixel-small centered">2022</p>
                  <p className="action black centered mt-15 mobile-dot">
                    cool elf jpegs
                    <br />
                    minted on
                    <br />
                    NEAR Protocol
                  </p>
                </div>
              </div>
            </div>
            <div className="main-side-p">
              <h1 className="white">FREAKY ELVES</h1>
              <p className="mt-15 no-desktop">
                Boost your Spin DEX experience with our spinning utility NFT
                collection
              </p>
              <p className="mt-30 no-mobile no-medium-desktop">
                Boost your Spin DEX experience with our spinning utility
                <br />
                NFT collection
              </p>
              <p className="mt-30 no-mobile no-big-desktop">
                Boost your Spin DEX experience with our spinning utility NFT
                collection
              </p>
              <div className="flex-row mt-20">
                <Wallet isHeader={false} />
                <div className="ml-30 flex-row">
                  <p className="grey">Built on</p>
                  <img className="ml-10" src={logoNear} alt="" />
                </div>
              </div>
              <div className="block border mt-20 mint-details">
                <h4>MINT DETAILS</h4>
                <h4 className="pixel-smaller mint-details-block">ALL NFTs HAVE BEEN MINTED</h4>
              </div>
            </div>
            <div className="pl-50 relative no-mobile">
              <div>
                <img
                  className="absolute pic pic-1"
                  src={elf_example_1}
                  alt=""
                />
                <img
                  className="absolute pic pic-2"
                  src={elf_example_2}
                  alt=""
                />
                <img
                  className="absolute pic pic-3"
                  src={elf_example_3}
                  alt=""
                />
                <img
                  className="absolute pic pic-4"
                  src={elf_example_4}
                  alt=""
                />
              </div>
              <div className="absolute bottom-0 label">
                <div className="absolute">
                  <p className="pixel-small centered">2022</p>
                  <p className="action black centered mt-15">
                    cool elf jpegs
                    <br />
                    minted on
                    <br />
                    NEAR Protocol
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
