import React, { useState } from "react";
import plus from "../assets/icons/plus.svg";
import cross from "../assets/icons/cross.svg";

const articles = [
  {
    head: "What is the Freaky Elves NFT collection?",
    body: "Freaky Elves are Spin’s NEAR-native utility collection consisting  of 2022 generative art JPEGs. Each NFT offers its holder priority access to Spin’s IDO, as well as premium social and product features.",
  },
  {
    head: "How to mint a Freaky Elf?",
    body: `The Freaky Elves minting is scheduled for March 10, 2022, at 15:00 UTC. <br /> <br />
    NFTs can be obtained in two ways: through free and paid mint. To be eligible for the free minting, users need to win in our Freaky Elves competitions announced on the project’s official socials: Twitter, Discord, and Telegram. Paid mint will cost 0.7 NEAR and will run on a first-come-first-served basis.  <br /> <br />
    Both free and paid mintors are required to join the White List to participate in minting. Please note that Discord roles are just a social indicator. If you joined WL, you will be able to join minting no matter if you have a role on DS server or not.`,
  },
  {
    head: "How many NFTs can I mint?",
    body: "Each user can mint up to 10 NFTs in the paid mints section + free NFTs they won in contests of for participating in Spin’s Perps v1 testing in November 2021. Please note that we’ll reserve free NFTs for users who won in Spin’s contests. Winning one contest grants the winner 1 NFT. For paid mints, minting will run on a first-come-first-served basis.",
  },
  {
    head: "How to trade Freaky Elves after minting?",
    body: "We have established a long-term partnership with <a target='_black' href='https://paras.id/'>Paras</a> — the largest NFT marketplace on NEAR protocol that will become the core marketplace for Freaky Elves’ secondary market. After minting is over, the Spin’s Freaky Elves collection will be listed on the Paras marketplace.",
  },
  {
    head: "How will priority access to Spin’s IDO work?",
    body: `Freaky Elves NFT holders will have priority access to Spin’s IDO, meaning, that they will be the first to join the IDO and buy Spin’s project tokens and thus get a 100% chance to have an IDO allocation. After this stage is over, other users that do not have an NFT will be able to join IDO.<br /> <br /> Please note that the details about the IDO date, launchpads, and allocation size  will be announced later.`,
  },
];

const Collapse = ({ head, body }) => {
  const [isOpened, setOpened] = useState(false);
  return (
    <div className="radius bg-light collapse">
      <div
        className="flex-row flex-full pointer collapse-clicker"
        onClick={() => setOpened(!isOpened)}
      >
        <h4
          style={{
            opacity: isOpened ? 1 : 0.4,
            color: isOpened ? "#B3EAEE" : "white",
          }}
        >
          {head}
        </h4>
        <img src={isOpened ? cross : plus} alt="" />
      </div>
      {isOpened && (
        <div className="collapse-content">
          <p dangerouslySetInnerHTML={{ __html: body }}></p>
        </div>
      )}
    </div>
  );
};

const Faq = () => {
  return (
    <div className="bg bg-bottom faq padding-base" id="faq">
      <h2>Frequently Asked Questions</h2>
      <div className="mt-30">
        {articles.map((article, i) => (
          <Collapse {...article} key={i} />
        ))}
      </div>
    </div>
  );
};

export default Faq;
