import * as React from "react";
import Blocks from "../components";
import Helmet from "react-helmet";
import OkModal from "../components/OkModal";

const pageStyles = {
  color: "#232129",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};

const IndexPage = ({location}) => {
  const [modal] = React.useState(
    parseUrlParamsForModal(location)
  );

  React.useEffect(() => {}, []);

  const [isMenuOpened, setIsMenuOpened] = React.useState(false);

  function menuFlippedHandler(isMenuOpened) {
    setIsMenuOpened(isMenuOpened);

    if (isMenuOpened) {
      document.getElementsByTagName("html")[0].style = "overflow-y: hidden;";
    } else {
      document.getElementsByTagName("html")[0].style = "overflow-y: auto;";
    }
  }

  return (
    <main style={pageStyles}>
      <Helmet
        title={"Freaky Elves utility NFTs by Spin"}
        description={
          "Your NEAR-native ticket to Spin's IDO, special product & social features"
        }
        meta={[
          { property: `og:image`, content: "https://nft.spin.fi/social.png" },
          { property: `twitter:card`, content: "summary_large_image" },
        ]}
        htmlAttributes={{
          lang: "en",
        }}
      />
      <Blocks.Feature menuFlippedHandler={(isOpened) => { menuFlippedHandler(isOpened); }} />
      { !isMenuOpened ? <Blocks.IDO /> : null }
      { !isMenuOpened ? <Blocks.Comics /> : null }
      { !isMenuOpened ? <Blocks.Attributes /> : null }
      { !isMenuOpened ? <Blocks.Faq /> : null }
      { !isMenuOpened ? <Blocks.Footer /> : null }
      { !isMenuOpened ? <OkModal state={modal} /> : null }
    </main>
  );
};

function parseUrlParamsForModal(location) {
  const searchParams = new URLSearchParams(location.search);

  let initModal = {
    isOpened: false,
    type: OkModal.UNDEFINED,
  };

  if (searchParams.get("errorCode")) {
    initModal = {
      isOpened: true,
      type: OkModal.FAILED_MODAL_TYPE,
    };
  } else if (searchParams.get("transactionHashes")) {
    initModal = {
      isOpened: true,
      type: OkModal.SUCCESS_MODAL_TYPE,
    };
  }

  return initModal;
}

export default IndexPage;
