import React, { useState } from "react";
import { data } from "../data";
import Wallet from "./Wallet";

const distribution = [
  {
    percent: 42.26,
    name: "Common",
    color: "#3C97D2",
  },
  {
    percent: 31.2,
    name: "Uncommon",
    color: "#97DFFC",
  },
  {
    percent: 20.8,
    name: "Rare",
    color: "#B5FED9",
  },
  {
    percent: 3.96,
    name: "Epic",
    color: "#FF6A9C",
  },
  {
    percent: 1.78,
    name: "Legendary",
    color: "#C25AFF",
  },
];
const pages = ["Background", "Body", "Outfit", "Hat", "Ears", "Mouth", "Eyes"];

const Card = ({ title, tier, rarity, percent, pic }) => {
  return (
    <div className="relative card radius border pointer">
      <img width="100%" src={pic} alt="" />
      <div className="py-10 px-20">
        <h4>{title.toUpperCase()}</h4>
        {/* <p>{tier}</p> *}
        {/*
        <p className="grey">Rarity:</p>
        <p className="pixel-smallest white">
          {{rarity}/2022 - {percent}%}
          {percent}%
        </p>
        */}
      </div>
    </div>
  );
};

const Attributes = () => {
  const [page, setPage] = useState("Background");
  const [attribute, setAttribute] = useState(-1);

  function showAttribute(value) {
    setAttribute(value);
  }
  function hideAttribute() {
    setAttribute(-1);
  }
  return (
    <div className="padding-base bg attrs" id="attributes">
      <h2>Attributes</h2>
      {/* 
      <div
        className="attrs-wrapper mt-30 rarity-attr-anim"
        data-attr={attribute}
        onMouseLeave={hideAttribute}
      >
        <h4>RARITY DISTRIBUTION</h4>
        <div className="flex-row mt-30">
          {distribution.map((item, i) => (
            <div
              key={i}
              onMouseEnter={() => showAttribute(i)}
              onMouseLeave={hideAttribute}
              className="attrs-chart-block"
              style={{ width: `${item.percent}%`, background: item.color }}
            ></div>
          ))}
        </div>
        <div className="attrs-map mt-20">
          {distribution.map((item, i) => (
            <div
              key={i}
              onMouseEnter={() => showAttribute(i)}
              onMouseLeave={hideAttribute}
              className={`flex-row ${i !== 0 ? "attrs-ml" : ""}`}
            >
              <p style={{ color: item.color }} className="pixel-smallest">
                {item.percent}%
              </p>
              <p className="ml-10 action">{item.name}</p>
            </div>
          ))}
        </div>
      </div>
      */}
      <div className="mt-60 flex-row attrs-pages">
        {pages.map((_page, i) => (
          <div
            className={`radius pointer py-13 px-14 ${i !== 0 ? " ml-20" : ""}`}
            key={_page}
            style={{
              background: page === _page ? "#B3EAEE" : "#1F222D",
            }}
            onClick={() => setPage(_page)}
          >
            <p className={`action ${page === _page ? "black" : "white"}`}>
              {_page}
            </p>
          </div>
        ))}
      </div>
      <div className="attrs-cards mt-30">
        {data
          .filter((attr) => attr.type === page.toLowerCase())
          .map((attr, i) => (
            <Card {...attr} key={i} />
          ))}
      </div>
      <div className="flex-row attrs-button">
        <Wallet />
      </div>
    </div>
  );
};

export default Attributes;
