import images from '../assets/images'

export const data = [
    {
        title: 'peachy sky',
        type: 'background',
        tier: 'Common',
        rarity: 312,
        percent: 15.43,
        pic: images.bg.Peachy_sky_bg
    }, 
    {
        title: 'graphite sky',
        type: 'background',
        tier: 'Common',
        rarity: 360,
        percent: 17.80,
        pic: images.bg.Graphite_sky_bg
    },
     
    {
        title: 'violet sky',
        type: 'background',
        tier: 'Common',
        rarity: 390,
        percent: 19.29,
        pic: images.bg.Violet_sky_bg
    }, 
    {
        title: 'mint sky',
        type: 'background',
        tier: 'Uncommon',
        rarity: 250,
        percent: 12.36,
        pic: images.bg.Mint_sky_bg
    }, 
    {
        title: 'blue sky',
        type: 'background',
        tier: 'Common',
        rarity: 404,
        percent: 19.98,
        pic: images.bg.Blue_sky_bg
    }, 
    {
        title: 'golden sky',
        type: 'background',
        tier: 'Rare',
        rarity: 90,
        percent: 4.45,
        pic: images.bg.Golden_sky_bg
    }, 
    {
        title: 'ivory sky',
        type: 'background',
        tier: 'Rare',
        rarity: 150,
        percent: 7.42,
        pic: images.bg.Ivory_sky_bg
    }, 
    {
        title: 'galaxy',
        type: 'background',
        tier: 'Epic',
        rarity: 48,
        percent: 2.37,
        pic: images.bg.Galaxy_bg
    }, 
    {
        title: 'aurora',
        type: 'background',
        tier: 'Legendary',
        rarity: 18,
        percent: 0.89,
        pic: images.bg.Aurora_bg
    }, 
    {
        title: 'pale',
        type: 'body',
        tier: 'Common',
        rarity: 323,
        percent: 15.97,
        pic: images.body.Pale_body
    }, 
    {
        title: 'bronzed',
        type: 'body',
        tier: 'Uncommon',
        rarity: 190,
        percent: 9.40,
        pic: images.body.Bronzed_body
    }, 
    {
        title: 'matrix',
        type: 'body',
        tier: 'Common',
        rarity: 363,
        percent: 17.95,
        pic: images.body.Matrix_body
    }, 
    {
        title: 'tribal',
        type: 'body',
        tier: 'Common',
        rarity: 325,
        percent: 16.07,
        pic: images.body.Tribal_body
    }, 
    {
        title: 'gop stop',
        type: 'body',
        tier: 'Uncommon',
        rarity: 202,
        percent: 9.99,
        pic: images.body.Gop_stop_body
    }, 
    {
        title: 'nerd',
        type: 'body',
        tier: 'Common',
        rarity: 314,
        percent: 15.53,
        pic: images.body.Nerd_body
    }, 
    {
        title: 'witcher',
        type: 'body',
        tier: 'Rare',
        rarity: 113,
        percent: 5.59,
        pic: images.body.Witcher_body
    }, 
    {
        title: 'mechanic',
        type: 'body',
        tier: 'Epic',
        rarity: 48,
        percent: 2.37,
        pic: images.body.Mechanic_body
    }, 
    {
        title: 'druid',
        type: 'body',
        tier: 'Legendary',
        rarity: 33,
        percent: 1.63,
        pic: images.body.Druid_body
    }, 
    {
        title: 'avatar',
        type: 'body',
        tier: 'Rare',
        rarity: 111,
        percent: 5.49,
        pic: images.body.Avatar_body
    }, 
    {
        title: 'geek',
        type: 'eyes',
        tier: 'Uncommon',
        rarity: 222,
        percent: 10.98,
        pic: images.eyes.Geek_eyes
    }, 
    {
        title: 'laser eyes',
        type: 'eyes',
        tier: 'Uncommon',
        rarity: 199,
        percent: 9.84,
        pic: images.eyes.Laser_eyes_eyes
    }, 
    {
        title: 'nerd',
        type: 'eyes',
        tier: 'Uncommon',
        rarity: 177,
        percent: 8.75,
        pic: images.eyes.Nerd_eyes
    }, 
    {
        title: 'druid',
        type: 'eyes',
        tier: 'Uncommon',
        rarity: 214,
        percent: 10.58,
        pic: images.eyes.Druid_eyes
    }, 
    {
        title: 'gem hunter',
        type: 'eyes',
        tier: 'Common',
        rarity: 332,
        percent: 16.42,
        pic: images.eyes.Gem_hunter_eyes
    }, 
    {
        title: 'witcher',
        type: 'eyes',
        tier: 'Common',
        rarity: 388,
        percent: 19.19,
        pic: images.eyes.Witcher_eyes
    }, 
    {
        title: 'mechanic',
        type: 'eyes',
        tier: 'Rare',
        rarity: 105,
        percent: 5.19,
        pic: images.eyes.Mechanic_eyes
    }, 
    {
        title: 'artist',
        type: 'eyes',
        tier: 'Epic',
        rarity: 58,
        percent: 2.87,
        pic: images.eyes.Artist_eyes
    }, 
    {
        title: 'punk',
        type: 'eyes',
        tier: 'Uncommon',
        rarity: 192,
        percent: 9.50,
        pic: images.eyes.Punk_eyes
    }, 
    {
        title: 'tribal',
        type: 'eyes',
        tier: 'Rare',
        rarity: 109,
        percent: 5.39,
        pic: images.eyes.Tribal_eyes
    }, 
    {
        title: 'matrix',
        type: 'eyes',
        tier: 'Legendary',
        rarity: 26,
        percent: 1.29,
        pic: images.eyes.Matrix_eyes
    }, 
    {
        title: 'artist',
        type: 'outfit',
        tier: 'Uncommon',
        rarity: 207,
        percent: 10.24,
        pic: images.outfit.Artist_outfit
    }, 
    {
        title: 'punk',
        type: 'outfit',
        tier: 'Rare',
        rarity: 144,
        percent: 7.12,
        pic: images.outfit.Punk_outfit
    }, 
    {
        title: 'nerd',
        type: 'outfit',
        tier: 'Common',
        rarity: 292,
        percent: 14.44,
        pic: images.outfit.Nerd_outfit
    }, 
    {
        title: 'x10',
        type: 'outfit',
        tier: 'Rare',
        rarity: 101,
        percent: 5.00,
        pic: images.outfit.x10_outfit
    }, 
    {
        title: 'trader',
        type: 'outfit',
        tier: 'Uncommon',
        rarity: 172,
        percent: 8.51,
        pic: images.outfit.Trader_outfit
    }, 
    {
        title: 'black dragon',
        type: 'outfit',
        tier: 'Legendary',
        rarity: 12,
        percent: 0.59,
        pic: images.outfit.Black_dragon_outfit
    }, 
    {
        title: 'witcher',
        type: 'outfit',
        tier: 'Uncommon',
        rarity: 172,
        percent: 8.51,
        pic: images.outfit.Witcher_outfit
    }, 
    {
        title: 'biker',
        type: 'outfit',
        tier: 'Rare',
        rarity: 145,
        percent: 7.17,
        pic: images.outfit.Biker_outfit
    }, 
    {
        title: 'nude',
        type: 'outfit',
        tier: 'Rare',
        rarity: 103,
        percent: 5.09,
        pic: images.nude.Nude
    }, 
    {
        title: 'angel',
        type: 'outfit',
        tier: 'Rare',
        rarity: 77,
        percent: 3.81,
        pic: images.outfit.Angel_outfit
    }, 
    {
        title: 'druid',
        type: 'outfit',
        tier: 'Rare',
        rarity: 95,
        percent: 4.70,
        pic: images.outfit.Druid_outfit
    }, 
    {
        title: 'tribal',
        type: 'outfit',
        tier: 'Epic',
        rarity: 48,
        percent: 2.37,
        pic: images.outfit.Tribal_outfit
    }, 
    {
        title: 'gem hunter',
        type: 'outfit',
        tier: 'Legendary',
        rarity: 22,
        percent: 1.09,
        pic: images.outfit.Gem_hunter_outfit
    }, 
    {
        title: 'galaxy',
        type: 'outfit',
        tier: 'Epic',
        rarity: 56,
        percent: 2.77,
        pic: images.outfit.Galaxy_outfit
    }, 
    {
        title: 'matrix',
        type: 'outfit',
        tier: 'Epic',
        rarity: 44,
        percent: 2.18,
        pic: images.outfit.Matrix_outfit
    }, 
    {
        title: 'serious business',
        type: 'outfit',
        tier: 'Epic',
        rarity: 56,
        percent: 2.77,
        pic: images.outfit.Seriuos_business_outfit
    }, 
    {
        title: 'geek',
        type: 'outfit',
        tier: 'Common',
        rarity: 276,
        percent: 13.65,
        pic: images.outfit.Geek_outfit
    }, 
    {
        title: 'punk',
        type: 'hat',
        tier: 'Uncommon',
        rarity: 180,
        percent: 8.90,
        pic: images.hat.Punk_hat
    }, 
    {
        title: 'dreadlocks',
        type: 'hat',
        tier: 'Uncommon',
        rarity: 173,
        percent: 8.56,
        pic: images.hat.Dreadlocks_hat
    }, 
    {
        title: 'gop stop',
        type: 'hat',
        tier: 'Rare',
        rarity: 111,
        percent: 5.49,
        pic: images.hat.Gop_stop_hat
    }, 
    {
        title: 'geek',
        type: 'hat',
        tier: 'Common',
        rarity: 359,
        percent: 17.75,
        pic: images.hat.Geek_hat
    }, 
    {
        title: 'pink',
        type: 'hat',
        tier: 'Rare',
        rarity: 136,
        percent: 6.73,
        pic: images.hat.Pink_hat
    }, 
    {
        title: 'witcher',
        type: 'hat',
        tier: 'Uncommon',
        rarity: 172,
        percent: 8.51,
        pic: images.hat.Witcher_hat
    }, 
    {
        title: 'spartan',
        type: 'hat',
        tier: 'Legendary',
        rarity: 11,
        percent: 0.54,
        pic: images.hat.Spartan_hat
    }, 
    {
        title: 'lumberjack',
        type: 'hat',
        tier: 'Rare',
        rarity: 110,
        percent: 5.44,
        pic: images.hat.Lumberjack_hat
    }, 
    {
        title: 'mcdonalds',
        type: 'hat',
        tier: 'Uncommon',
        rarity: 164,
        percent: 8.11,
        pic: images.hat.McDonalds_hat
    }, 
    {
        title: 'serious business',
        type: 'hat',
        tier: 'Rare',
        rarity: 75,
        percent: 3.71,
        pic: images.hat.Serious_business_hat
    }, 
    {
        title: 'artist',
        type: 'hat',
        tier: 'Rare',
        rarity: 138,
        percent: 6.82,
        pic: images.hat.Artist_hat
    }, 
    {
        title: 'foxy',
        type: 'hat',
        tier: 'Legendary',
        rarity: 18,
        percent: 0.89,
        pic: images.hat.Foxy_hat
    }, 
    {
        title: 'nerd',
        type: 'hat',
        tier: 'Legendary',
        rarity: 8,
        percent: 0.40,
        pic: images.hat.Nerd_hat
    }, 
    {
        title: 'weird',
        type: 'hat',
        tier: 'Legendary',
        rarity: 21,
        percent: 1.04,
        pic: images.hat.Weird_hat
    }, 
    {
        title: 'angel',
        type: 'hat',
        tier: 'Rare',
        rarity: 88,
        percent: 4.35,
        pic: images.hat.Angel_hat
    }, 
    {
        title: 'galaxy',
        type: 'hat',
        tier: 'Epic',
        rarity: 51,
        percent: 2.52,
        pic: images.hat.Galaxy_hat
    }, 
    {
        title: 'gem hunter',
        type: 'hat',
        tier: 'Rare',
        rarity: 101,
        percent: 5.00,
        pic: images.hat.Gem_hunter_hat
    }, 
    {
        title: 'curly',
        type: 'hat',
        tier: 'Rare',
        rarity: 85,
        percent: 4.20,
        pic: images.hat.Curly_hat
    }, 
    {
        title: 'aurora',
        type: 'hat',
        tier: 'Legendary',
        rarity: 21,
        percent: 1.04,
        pic: images.hat.Aurora_hat
    }, 
    {
        title: 'punk',
        type: 'mouth',
        tier: 'Rare',
        rarity: 130,
        percent: 6.43,
        pic: images.mouth.Punk_mouth
    }, 
    {
        title: 'calm',
        type: 'mouth',
        tier: 'Common',
        rarity: 455,
        percent: 22.50,
        pic: images.mouth.Calm_mouth
    }, 
    {
        title: 'smile',
        type: 'mouth',
        tier: 'Common',
        rarity: 393,
        percent: 19.44,
        pic: images.mouth.Smile_mouth
    }, 
    {
        title: 'bronzed',
        type: 'mouth',
        tier: 'Uncommon',
        rarity: 201,
        percent: 9.94,
        pic: images.mouth.Bronzed_mouth
    }, 
    {
        title: 'geek',
        type: 'mouth',
        tier: 'Rare',
        rarity: 147,
        percent: 7.27,
        pic: images.mouth.Geek_mouth
    }, 
    {
        title: 'gloomy',
        type: 'mouth',
        tier: 'Epic',
        rarity: 56,
        percent: 2.77,
        pic: images.mouth.Gloomy_mouth
    }, 
    {
        title: 'mask',
        type: 'mouth',
        tier: 'Rare',
        rarity: 106,
        percent: 5.24,
        pic: images.mouth.Mask_mouth
    }, 
    {
        title: 'witcher',
        type: 'mouth',
        tier: 'Rare',
        rarity: 112,
        percent: 5.54,
        pic: images.mouth.Witcher_mouth
    }, 
    {
        title: 'druid',
        type: 'mouth',
        tier: 'Uncommon',
        rarity: 179,
        percent: 8.85,
        pic: images.mouth.Druid_mouth
    }, 
    {
        title: 'artist',
        type: 'mouth',
        tier: 'Epic',
        rarity: 43,
        percent: 2.13,
        pic: images.mouth.Artist_mouth
    }, 
    {
        title: 'nerd',
        type: 'mouth',
        tier: 'Uncommon',
        rarity: 163,
        percent: 8.06,
        pic: images.mouth.Nerd_mouth
    }, 
    {
        title: 'lumberjack',
        type: 'mouth',
        tier: 'Legendary',
        rarity: 37,
        percent: 1.83,
        pic: images.mouth.Lumberjack_mouth
    }, 
    {
        title: 'trader',
        type: 'ears',
        tier: 'Rare',
        rarity: 122,
        percent: 6.03,
        pic: images.ears.Trader_ears
    }, 
    {
        title: 'bearish',
        type: 'ears',
        tier: 'Uncommon',
        rarity: 222,
        percent: 10.98,
        pic: images.ears.Bearish_ears
    }, 
    {
        title: 'bullish',
        type: 'ears',
        tier: 'Uncommon',
        rarity: 223,
        percent: 11.03,
        pic: images.ears.Bullish_ears
    }, 
    {
        title: 'witcher',
        type: 'ears',
        tier: 'Uncommon',
        rarity: 171,
        percent: 8.46,
        pic: images.ears.Witcher_ears
    }, 
    {
        title: 'geek',
        type: 'ears',
        tier: 'Uncommon',
        rarity: 164,
        percent: 8.11,
        pic: images.ears.Geek_ears
    }, 
    {
        title: 'punk',
        type: 'ears',
        tier: 'Rare',
        rarity: 140,
        percent: 6.92,
        pic: images.ears.Punk_ears
    }, 
    {
        title: 'nerd',
        type: 'ears',
        tier: 'Common',
        rarity: 280,
        percent: 13.85,
        pic: images.ears.Nerd_ears
    }, 
    {
        title: 'artist',
        type: 'ears',
        tier: 'Uncommon',
        rarity: 207,
        percent: 10.24,
        pic: images.ears.Artist_ears
    }, 
    {
        title: 'aurora',
        type: 'ears',
        tier: 'Epic',
        rarity: 53,
        percent: 2.62,
        pic: images.ears.Aurora_ears
    }, 
    {
        title: 'druid',
        type: 'ears',
        tier: 'Legendary',
        rarity: 25,
        percent: 1.24,
        pic: images.ears.Druid_ears
    }, 
]