import Nude from './Nude.png'
import Aurora_bg from './Background/Aurora.png'
import Blue_sky_bg from './Background/Blue_sky.png'
import Galaxy_bg from './Background/Galaxy.png'
import Golden_sky_bg from './Background/Golden_sky.png'
import Graphite_sky_bg from './Background/Graphite_sky.png'
import Ivory_sky_bg from './Background/Ivory_sky.png'
import Mint_sky_bg from './Background/Mint_sky.png'
import Peachy_sky_bg from './Background/Peachy_sky.png'
import Violet_sky_bg from './Background/Violet_sky.png'
import Avatar_body from './Body/Avatar.png'
import Bronzed_body from './Body/Bronzed.png'
import Druid_body from './Body/Druid.png'
import Gop_stop_body from './Body/Gop_stop.png'
import Matrix_body from './Body/Matrix.png'
import Mechanic_body from './Body/Mechanic.png'
import Nerd_body from './Body/Nerd.png'
import Pale_body from './Body/Pale.png'
import Tribal_body from './Body/Tribal.png'
import Witcher_body from './Body/Witcher.png'
import Artist_ears from './Ears/Artist.png'
import Aurora_ears from './Ears/Aurora.png'
import Bearish_ears from './Ears/Bearish.png'
import Bullish_ears from './Ears/Bullish.png'
import Druid_ears from './Ears/Druid.png'
import Geek_ears from './Ears/Geek.png'
import Nerd_ears from './Ears/Nerd.png'
import Punk_ears from './Ears/Punk.png'
import Trader_ears from './Ears/Trader.png'
import Witcher_ears from './Ears/Witcher.png'
import Artist_eyes from './Eyes/Artist.png'
import Druid_eyes from './Eyes/Druid.png'
import Geek_eyes from './Eyes/Geek.png'
import Gem_hunter_eyes from './Eyes/Gem_hunter.png'
import Laser_eyes_eyes from './Eyes/Laser_eyes.png'
import Matrix_eyes from './Eyes/Matrix.png'
import Mechanic_eyes from './Eyes/Mechanic.png'
import Nerd_eyes from './Eyes/Nerd.png'
import Punk_eyes from './Eyes/Punk.png'
import Tribal_eyes from './Eyes/Tribal.png'
import Witcher_eyes from './Eyes/Witcher.png'
import Angel_hat from './Hat/Angel.png'
import Artist_hat from './Hat/Artist.png'
import Aurora_hat from './Hat/Aurora.png'
import Curly_hat from './Hat/Curly.png'
import Dreadlocks_hat from './Hat/Dreadlocks.png'
import Foxy_hat from './Hat/Foxy.png'
import Galaxy_hat from './Hat/Galaxy.png'
import Geek_hat from './Hat/Geek.png'
import Gem_hunter_hat from './Hat/Gem_hunter.png'
import Gop_stop_hat from './Hat/Gop_stop.png'
import Lumberjack_hat from './Hat/Lumberjack.png'
import McDonalds_hat from './Hat/McDonalds.png'
import Nerd_hat from './Hat/Nerd.png'
import Pink_hat from './Hat/Pink.png'
import Punk_hat from './Hat/Punk.png'
import Serious_business_hat from './Hat/Serious_business.png'
import Spartan_hat from './Hat/Spartan.png'
import Weird_hat from './Hat/Weird.png'
import Witcher_hat from './Hat/Witcher.png'
import Artist_mouth from './Mouth/Artist.png'
import Bronzed_mouth from './Mouth/Bronzed.png'
import Calm_mouth from './Mouth/Calm.png'
import Druid_mouth from './Mouth/Druid.png'
import Geek_mouth from './Mouth/Geek.png'
import Gloomy_mouth from './Mouth/Gloomy.png'
import Lumberjack_mouth from './Mouth/Lumberjack.png'
import Mask_mouth from './Mouth/Mask.png'
import Nerd_mouth from './Mouth/Nerd.png'
import Punk_mouth from './Mouth/Punk.png'
import Smile_mouth from './Mouth/Smile.png'
import Witcher_mouth from './Mouth/Witcher.png'
import Angel_outfit from './Outfit/Angel.png'
import Artist_outfit from './Outfit/Artist.png'
import Biker_outfit from './Outfit/Biker.png'
import Black_dragon_outfit from './Outfit/Black_dragon.png'
import Druid_outfit from './Outfit/Druid.png'
import Galaxy_outfit from './Outfit/Galaxy.png'
import Geek_outfit from './Outfit/Geek.png'
import Gem_hunter_outfit from './Outfit/Gem_hunter.png'
import Matrix_outfit from './Outfit/Matrix.png'
import Nerd_outfit from './Outfit/Nerd.png'
import Punk_outfit from './Outfit/Punk.png'
import Seriuos_business_outfit from './Outfit/Seriuos_business.png'
import Trader_outfit from './Outfit/Trader.png'
import Tribal_outfit from './Outfit/Tribal.png'
import Witcher_outfit from './Outfit/Witcher.png'
import x10_outfit from './Outfit/x10.png'

export default {
    nude: {
        Nude
    },
    bg: {
        Aurora_bg,
        Blue_sky_bg,
        Galaxy_bg,
        Golden_sky_bg,
        Graphite_sky_bg,
        Ivory_sky_bg,
        Mint_sky_bg,
        Peachy_sky_bg,
        Violet_sky_bg,       
    },
    body: {
        Avatar_body,
        Bronzed_body,
        Druid_body,
        Gop_stop_body,
        Matrix_body,
        Mechanic_body,
        Nerd_body,
        Pale_body,
        Tribal_body,
        Witcher_body,       
    }, 
    ears: {
        Artist_ears,
        Aurora_ears,
        Bearish_ears,
        Bullish_ears,
        Druid_ears,
        Geek_ears,
        Nerd_ears,
        Punk_ears,
        Trader_ears,
        Witcher_ears,
    },
    eyes: {
        Artist_eyes,
        Druid_eyes,
        Geek_eyes,
        Gem_hunter_eyes,
        Laser_eyes_eyes,
        Matrix_eyes,
        Mechanic_eyes,
        Nerd_eyes,
        Punk_eyes,
        Tribal_eyes,
        Witcher_eyes,
    },
    hat: {
        Angel_hat,
        Artist_hat,
        Aurora_hat,
        Curly_hat,
        Dreadlocks_hat,
        Foxy_hat,
        Galaxy_hat,
        Geek_hat,
        Gem_hunter_hat,
        Gop_stop_hat,
        Lumberjack_hat,
        McDonalds_hat,
        Nerd_hat,
        Pink_hat,
        Punk_hat,
        Serious_business_hat,
        Spartan_hat,
        Weird_hat,
        Witcher_hat,
    },
    mouth: {
        Artist_mouth,
        Bronzed_mouth,
        Calm_mouth,
        Druid_mouth,
        Geek_mouth,
        Gloomy_mouth,
        Lumberjack_mouth,
        Mask_mouth,
        Nerd_mouth,
        Punk_mouth,
        Smile_mouth,
        Witcher_mouth,
    },
    outfit: {
        Angel_outfit,
        Artist_outfit,
        Biker_outfit,
        Black_dragon_outfit,
        Druid_outfit,
        Galaxy_outfit,
        Geek_outfit,
        Gem_hunter_outfit,
        Matrix_outfit,
        Nerd_outfit,
        Punk_outfit,
        Seriuos_business_outfit,
        Trader_outfit,
        Tribal_outfit,
        Witcher_outfit,
        x10_outfit,
    }
}