import React, { useEffect, useState } from "react";
import { genericHashLink } from "react-router-hash-link";
import GatsbyLink from "gatsby-link";
import logo from "../assets/icons/logo.svg";
import menu from "../assets/icons/menu-=.svg";
import menux from "../assets/icons/menu-x.svg";
import arrow from "../assets/icons/arrow.svg";
import logoTelegram from "../assets/icons/logo_tg.svg";
import logoDiscord from "../assets/icons/logo_ds.svg";
import logoTwitter from "../assets/icons/logo_tw.svg";
import Wallet from "./Wallet";

const HashLink = genericHashLink(GatsbyLink);

const Header = ({menuFlippedHandler}) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [isForceCloseMenu, setIsForceCloseMenu] = useState(false);

  return (
    <>
      <header className="flex-row flex-full bg-bottom padding-base">
        <DesktopHeader />
        <MobileHeader setIsMenuOpened={setIsMenuOpened} menuFlippedHandler={menuFlippedHandler} isForceCloseMenu={isForceCloseMenu} setIsForceCloseMenu={setIsForceCloseMenu} />
      </header>
      { isMenuOpened ? <MobileMenu setIsForceCloseMenu={setIsForceCloseMenu} /> : null }
    </>
  );
};

const DesktopHeader = () => (
  <div className="flex-row flex-full w-100 no-mobile">
    <div className="flex-row flex-left">
      <a target="_blank" rel="noreferrer" href="https://spin.fi">
        <img src={logo} alt="logo" className="header-logo pointer" />
      </a>
      <nav className="no-mobile flex-row flex-left ml-30 header-nav">
        <a target="_blank" rel="noreferrer" href="https://spin.fi">
          <div className="flex-row">
            <p className="action">Home</p>
            <img className="ml-10" src={arrow} alt="" />
          </div>
        </a>
        <a target="_blank" rel="noreferrer" href="https://paras.id/collection/spin-nft-contract.near">
          <div className="flex-row">
            <p className="action">Collection</p>
            <img className="ml-10" src={arrow} alt="" />
          </div>
        </a>
        <HashLink to="#about">
          <p className="action">About</p>
        </HashLink>
        <HashLink to="#faq">
          <p className="action">FAQ</p>
        </HashLink>
      </nav>
    </div>
    <div className="flex-row flex-right">
      <a target="_blank" rel="noreferrer" href="https://t.me/spin_fi">
        <div className="header-link mx-10">
          <img src={logoTelegram} alt="" />{" "}
        </div>{" "}
      </a>
      <a target="_blank" rel="noreferrer" href="https://discord.gg/GNdBEEj8ZP">
        <div className="header-link mx-10">
          <img src={logoDiscord} alt="" />{" "}
        </div>{" "}
      </a>
      <a target="_blank" rel="noreferrer" href="https://twitter.com/spin_fi">
        <div className="header-link mx-10">
          <img src={logoTwitter} alt="" />{" "}
        </div>{" "}
      </a>
      <Wallet isHeader={true} />
    </div>
  </div>
);

const MobileHeader = ({setIsMenuOpened, menuFlippedHandler, isForceCloseMenu, setIsForceCloseMenu}) => {
  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window?.addEventListener("orientationchange", function() {
        setIsOpened(false);
        setIsMenuOpened(false);
        menuFlippedHandler(false);
      }, false);
    }
  }, []);

  useEffect(() => {
    flipMenu(true);
  }, [isForceCloseMenu])

  function flipMenu(isOpened) {
    setIsOpened(!isOpened);
    setIsMenuOpened(!isOpened);
    menuFlippedHandler(!isOpened);
    setIsForceCloseMenu(false); 
  }

  return (
    <div className="flex-row flex-full w-100 no-desktop">
      <a target="_blank" rel="noreferrer" href="https://spin.fi">
        <img src={logo} alt="logo" className="header-logo pointer" />
      </a>
      <img src={isOpened ? menux : menu} alt={isOpened ? "menux" : "menu"} className="pointer mx-20" onClick={() => { flipMenu(isOpened) }} />
    </div>
  )
};

const MobileMenu = ({setIsForceCloseMenu}) => (
  <div className="mobile-menu relative gradient-main">
    <div className="flex-center grid-rows-one mt-60">
      <nav className="header-nav">
        <a target="_blank" rel="noreferrer" href="https://spin.fi">
          <div className="flex-row flex-center">
            <p className="action-menu centered">
              <span>Home</span>
              <img className="w-20-px" src={arrow} alt="" />
            </p>
          </div>
        </a>
        <a target="_blank" rel="noreferrer" href="https://paras.id/collection/spin-nft-contract.near">
          <div className="flex-row flex-center">
            <p className="action-menu centered">
              <span>Collection</span>
              <img className="w-20-px" src={arrow} alt="" />
            </p>
          </div>
        </a>
        <HashLink to="#about" onClick={() => { setIsForceCloseMenu(true); }}>
          <p className="action-menu centered mt-15">About</p>
        </HashLink>
        <HashLink to="#faq" onClick={() => { setIsForceCloseMenu(true); }}>
          <p className="action-menu centered mt-15">FAQ</p>
        </HashLink>
      </nav>
    </div>
    <div className="flex-center grid-rows-one mt-30">
      <div className="flex-row flex-center">
        <a className="mx-20" target="_blank" rel="noreferrer" href="https://t.me/spin_fi">
          <div className="header-link">
            <img className="w-35-px" src={logoTelegram} alt="" />{" "}
          </div>{" "}
        </a>
        <a className="mx-20" target="_blank" rel="noreferrer" href="https://discord.gg/GNdBEEj8ZP">
          <div className="header-link">
            <img className="w-35-px" src={logoDiscord} alt="" />{" "}
          </div>{" "}
        </a>
        <a className="mx-20" target="_blank" rel="noreferrer" href="https://twitter.com/spin_fi">
          <div className="header-link">
            <img className="w-35-px" src={logoTwitter} alt="" />{" "}
          </div>{" "}
        </a>
      </div>
    </div>
    <div className="flex-center grid-rows-one mt-30">
      <div className="flex-row flex-center">
        <Wallet isHeader={true} />
      </div>
    </div>
  </div>
);

export default Header;

