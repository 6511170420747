import React from "react";
import tg from "../assets/icons/logo_tg.svg";
import tw from "../assets/icons/logo_tw.svg";
import ds from "../assets/icons/logo_ds.svg";
import md from "../assets/icons/logo_md.svg";
import gitbook from "../assets/icons/logo_gitbook.svg";

const socials = [
  {
    name: "Telegram channel",
    link: "https://t.me/spin_fi",
    icon: tg,
  },
  {
    name: "Twitter",
    link: "https://twitter.com/spin_fi",
    icon: tw,
  },
  {
    name: "Discord",
    link: "https://discord.gg/GNdBEEj8ZP",
    icon: ds,
  },
  {
    name: "Medium",
    link: "https://spin-fi.medium.com/",
    icon: md,
  },
  {
    name: "Gitbook",
    link: "https://docs.spin.fi/",
    icon: gitbook,
  },
];

const Footer = () => {
  return (
    <footer className="bg padding-base">
      <h2>Join the family</h2>
      <div className="footer-links">
        {socials.map((social, i) => (
          <a
            href={social.link}
            target="_blank"
            rel="noreferrer"
            className="footer-a"
            key={i}
          >
            <div className="footer-link flex-row radius">
              <img src={social.icon} alt="" />
              <p className="ml-10 action-small">{social.name}</p>
            </div>
          </a>
        ))}
      </div>
    </footer>
  );
};

export default Footer;
