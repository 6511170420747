import React from "react";
import elf_example_1 from "../assets/icons/elf-example-1.png";
import elf_example_3 from "../assets/icons/elf-example-3.png";
import elf_example_4 from "../assets/icons/elf-example-4.png";

const IDO = () => {
  return (
    <div className="padding-base py-60 pt-90 bg" id="about">
      <h2 className="no-mobile">
        Each Elf is your{" "}
        <span className="ticket-label">Ticket to Spin's IDO</span> and Much More
      </h2>
      <h2 className="no-desktop">
        Each Elf is your <br />{" "}
        <span
          className="ticket-label"
          style={{ paddingTop: "10px", lineHeight: "200%" }}
        >
          Ticket to Spin's IDO
        </span>{" "}
        <br /> and Much More
      </h2>
      <div className="ido-blocks mt-30">
        <div className="b-color-green block block-anim border ido__block">
          <h4 className="green">#IDO</h4>
          <p className="mt-15">
            The Elwyn NFT is your personal priority pass for boarding on the
            Spin IDO. In other words, zero chance to miss out!
          </p>
          <img src={elf_example_3} alt="" className="ido__block-img" />
        </div>
        <div className="b-color-pink block block-anim border ido-block-m ido__block">
          <h4 className="pink">#PRODUCT</h4>
          <p className="mt-15">
            Unlock premium product features on the Spin DEX: early access to
            alpha features, special avatars and much more!
          </p>
          <img src={elf_example_4} alt="" className="ido__block-img" />
        </div>
        <div className="b-color-purple block block-anim border ido-block-m ido__block">
          <h4 className="purple">#SOCIAL</h4>
          <p className="mt-15">
            Get special roles on Discord and access to a private chat for NFT
            holders to initiate community contests, activities, and more!
          </p>
          <img src={elf_example_1} alt="" className="ido__block-img" />
        </div>
      </div>
    </div>
  );
};

export default IDO;
