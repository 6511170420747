import React from "react";
import comicsImg from "../assets/icons/comics.png";
import Wallet from "./Wallet";

const Comics = () => {
  return (
    <div className="padding-base bg py-30">
      <div className="bg-light radius comics-p">
        <img className="comics" width="100%" src={comicsImg} alt="comics" />
        <div className="flex-row comics-buttons">
          <Wallet />
        </div>
      </div>
    </div>
  );
};

export default Comics;
