import React from "react";
import Modal from 'react-modal';

const customStyles = {
    content: {
        'top': '50%',
        'left': '50%',
        'right': 'auto',
        'bottom': 'auto',
        'marginRight': '-50%',
        'transform': 'translate(-50%, -50%)',
        'padding': '10px 20px 20px 20px',
        'background': '#1F222D',
        'boxShadow': '0px 0px 100px rgba(0, 0, 0, 0.6)',
        'borderRadius': '10px',
        'border': 'none',
        'minWidth': '380px',
        'minHeight': '246px',
    },
    overlay: {
        'zIndex': '100',
        'backgroundColor': 'rgba(0, 0, 0, 0.25)'
    }
  };

export default class OkModal extends React.Component { 
    static UNDEFINED = 0;
    static SUCCESS_MODAL_TYPE = 1;
    static FAILED_MODAL_TYPE = 2;

    styles = {
        text: "Minted successfully!",
        class: "link-button-light",
    };

    constructor(params) {
        super(params);

        this.state = {
            isModalOpen: params.state.isOpened,
            type: params.state.type,
        };

        if (this.state.type == OkModal.FAILED_MODAL_TYPE) {
            this.styles = {
                text: "Transaction failed",
                class: "link-button",
            };
        }

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    openModal() {
        this.setState({
            isModalOpen: true,
            type: this.state.type,
        });
    }

    closeModal() {
        this.setState({
            isModalOpen: false,
            type: this.state.type,
        });
    }

    render() {
        if (!this.state.isModalOpen || this.state.type === OkModal.UNDEFINED) {
            return null;
        }

        return (
            <div>
                <Modal
                    isOpen={this.state.isModalOpen}
                    onRequestClose={() => { this.closeModal() }}
                    style={customStyles}
                    ariaHideApp={false}
                >
                    <div className="row mb-20">
                        <div className="right times" onClick={() => { this.closeModal(); }}>
                        </div>
                    </div>
                    <div className="row">
                        <h2 className="centered white">{this.styles.text}</h2>
                    </div>
                    <div className="row">
                        <a
                            className="header-cta"
                            onClick={() => { this.closeModal(); }}
                        >
                            <div className={this.styles.class}>
                                <p className="centered action black">Ok</p>
                            </div>
                        </a>
                    </div>
                </Modal>
            </div>
        );
    }
}