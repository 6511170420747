import React from "react";

// necessary for developing purposes in browser
global.Buffer = global.Buffer || require('buffer').Buffer;

export default class Wallet extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isHeader: props.isHeader,
        };
    }

    render() {
        return (
            <a target="_blank" rel="noreferrer" href="https://paras.id/collection/spin-nft-contract.near"
            >
                <div className="link-button">
                    <p className="action black centered">{ this.state.isHeader ? "View collection" : "Market" }</p>
                </div>
            </a>
        )
    }
}