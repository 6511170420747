import Attributes from "./Attributes";
import Comics from "./Comics";
import Faq from "./Faq";
import Feature from "./Feature";
import Footer from "./Footer";
import IDO from "./IDO";

const modules = {
    Attributes,
    Comics,
    Faq,
    Feature,
    Footer,
    IDO
} 
export default modules